@import "@/app/styles/_mantine.scss";
.baseFormItem {

	&:global(.textInput), &:global(.passwordInput) {
		@include apply-input-styles;
	}

	&:global(.checkbox) {
		@include apply-checkbox-styles;
	}


	&:global(.select) {
		@include apply-select-styles;

		:global(.mantine-Popover-dropdown) {
			@include apply-select-dropdown-styles;
		}
	}

	&:global(.multiSelect) {
		@include apply-multiselect-styles;

		:global(.mantine-Popover-dropdown) {
			@include apply-select-dropdown-styles;
		}
	}

	&:global(.checkboxGroup) {

		:global(.mantine-CheckboxGroup-root) {

			& > div {
				display: flex;
				flex-direction: column;
				row-gap: 48px;

				&:global(:has(+ .mantine-InputWrapper-error)) {
					margin-bottom: 16px;
				}
			}
		}
	}
}

@import "@/app/styles/_mantine.scss";
.root {}

.passwordRequirement {
	display: flex;
	align-items: center;
	color: var(--mantine-color-red-8);
	font-size: 12px;

	&.meets {
		color: var(--mantine-color-teal-9);
	}
}

.passwordRequirementIcon {
	width: 14px;
	height: 14px;
}

.passwordRequirementLabel {
	margin-left: 7px;
}

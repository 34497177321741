@import "@/app/styles/_mantine.scss";
.container {
	background-color: var(--mantine-color-gray-0);
}

.root {
	height: 100%;
	flex-direction: column;
	padding: var(--mantine-spacing-lg);

	@include smaller-than($mantine-breakpoint-md) {
		justify-content: flex-start;
	}
}

.paper {
	width: 100%;
	max-width: 500px;

	@include smaller-than($mantine-breakpoint-md) {
		max-width: none;
	}
}

.title {
	text-align: center;
	margin: 0;
	margin-bottom: 12px;
	font-size: 32px;

	@include smaller-than($mantine-breakpoint-md) {
		font-size: 22px;
	}
}

.caption {
	font-size: 10px;
	color: #6C7278;
	margin: 0;
	margin-bottom: 32px;
	text-align: center;
}

@import "@/app/styles/_mantine.scss";
.createTable {
	padding: $desktop-block-padding $desktop-inline-padding;
	background-color: $light-background;
	flex-grow: 1;

	@include smaller-than($mantine-breakpoint-md) {
		padding: $mobile-block-padding $mobile-inline-padding;
	}
}

.title {
	@include h1;
	margin: 0;
}

.headerBlock {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 32px;
}

.backBtn {
	color: $dark;
}

.form {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.formSection {
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	padding: 32px;
	background-color: white;
}

.formSectionTitle {
	margin: 0;
	font-size: 24px;
	font-weight: 700;
}

.formSectionFields {
	display: flex;
	flex-direction: column;
	padding: 15px;
}

.productsSelectsWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 16px;

	:global(.select) {
		flex-grow: 1;
	}
}

.formField {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 16px;

	&:global(.horizontalInputs) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 24px 0;

		&:not(:last-child) {
			border-bottom: 1px solid $light-grey;
		}

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		:global(.textInput), :global(.select) {
			flex-grow: 1;
		}
	}
}

.formFieldInputs {
	display: flex;
	column-gap: 16px;
	width: 100%;
}

.outerLabel {
	font-size: 16px;
	font-weight: 500;
}

.calculateBtn {
	width: auto;
	align-self: flex-end;
	padding-inline: 44px;

	@include apply-primary-dark-btn
}

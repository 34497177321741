@import "@/app/styles/_mantine.scss";
.root {
	height: 100%;

	@include smaller-than($mantine-breakpoint-md) {
		height: auto;
	}
}

.leftSide {
	height: 100%;
	position: relative;
	padding: 88px 75px;

	@include smaller-than($mantine-breakpoint-md) {
		padding: 15px;
		min-height: 125px;
		width: 100%;
	}
}

.leftSideLogo {
	position: relative;
}

.rightSide {
	height: 100%;
	padding: 15px;

	@include smaller-than($mantine-breakpoint-md) {
		width: 100%;
	}
}

.rightSideInner {
	max-width: 365px;

	@include smaller-than($mantine-breakpoint-md) {
		max-width: none;
	}
}

.rightSideTitle {
	text-align: left;
	margin: 0;
	margin-bottom: 24px;
	font-size: 32px;

	@include smaller-than($mantine-breakpoint-md) {
		margin-bottom: 12px;
		text-align: center;
		font-size: 16px;
	}
}

.rightSideHint {
	font-size: 16px;
	color: #6C7278;
	text-align: left;
	margin: 0;
	margin-bottom: 37px;

	@include smaller-than($mantine-breakpoint-md) {
		margin-bottom: 24px;
		font-size: 12px;
		text-align: center;
	}
}

.form {

	.rememberMe {
		color: #6C7278;
	}
}

.formGrid {
	margin-bottom: 24px;
}

.forgotPasswordLinkWrapper {
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: end;
}

.forgotPasswordLink {

}

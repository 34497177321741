@import "@/app/styles/_mantine.scss";
.adminUserListTable {
	padding: $desktop-block-padding $desktop-inline-padding;
	background-color: $light-background;
	flex-grow: 1;

	@include smaller-than($mantine-breakpoint-md) {
		padding: $mobile-block-padding $mobile-inline-padding;
	}
}

.title {
	@include h1;
	margin: 0;
	margin-bottom: 24px;
}

.filterWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	flex-wrap: wrap;
}

.filterInputWrapper {
	width: 100%;
	max-width: 50%;
	border-bottom: 1px solid $light-grey;
}

.filterInput {

	:global(.tabler-icon) {
		width: 17px;
		height: 17px;
	}
}

.tableWrapper {
	position: relative;

}

.table {
	min-height: 300px;

	@include apply-table-styles;
}

.tableActions {
	display: flex;
	gap: 16.5px;
}

.tableAction {

	--ai-size: 25px;
}

.tableActionIcon {
	color: rgba(87, 95, 110, 100%);
}

.tableActionModal {
	@include apply-modal-styles;
}

.createUserBtn {
	@include apply-primary-dark-btn;
}

.createUserModal {
	@include apply-modal-styles;
}

.createUserModalBodyContent {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.uploadTableBtn {
	@include apply-primary-dark-btn;
}

.uploadTableModal {
	@include apply-modal-styles;
}

.uploadTableModalBodyContent {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.uploadTableSelectProduct {
	@include apply-select-styles;
}

.uploadTableSelectProductDropdown {
	@include apply-select-dropdown-styles;
}

.uploadTableDropzone {

	&:global(.hasError) {
		border-color: var(--mantine-color-error);
	}
}

.dropzoneNotUploaded {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: 8px;
	pointer-events: none;
}

.dropzoneNotUploadedTitle {
	margin: 0;
	color: var(--mantine-primary-color-filled);
}

.dropzoneNotUploadedDescription {
	margin: 0;
	color: $grey;
	font-size: 14px;
}

.dropzoneUploaded {
	pointer-events: all;
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.dropzoneError {
	padding: 7px 0 0;
	margin: 0;
	color: var(--mantine-color-error);
	font-size: 12px;
}

.dropzoneFileInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dropzoneUploadedLeft {
	display: flex;
	align-items: center;
	gap: 17px;
}

.dropzoneUploadedRemoveBtn {
	color: $dark;
}

.dropzoneUploadedFileIcon {
	min-width: 48px;
	width: 48px;
	height: 48px;
}

.dropzoneUploadedFileName {
	font-size: 16px;
	font-weight: 400;
	max-width: 200px;
}

.dropzoneProgressWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.dropzoneProgress {

}

.dropzoneProgressText {
	text-align: center;
	margin: 0;
	font-size: 12px;
	color: $grey;
}

